<script>
  import { createEventDispatcher } from "svelte";
  import { fade } from "svelte/transition";
  import { _ } from "svelte-i18n";

  export let isOpen = false;
  const dispatch = createEventDispatcher();
  let isCheckboxChecked = false;

  function agree() {
    if (isCheckboxChecked) {
      dispatch("consent", { agreed: true });
    }
  }
  function disagree() {
    dispatch("consent", { agreed: false });
  }

  function toggleCheckbox() {
    isCheckboxChecked = !isCheckboxChecked;
  }
</script>

{#if isOpen}
  <div class="modal-consent" transition:fade>
    <div class="modal-content-consent">
      <h2><b>{@html $_("privacy_and_consent.Your Privacy & Consent")}</b></h2>
      <p>
        <b style="text-align: left;">
          {@html $_(
            "privacy_and_consent.We know this is boring stuff, but data privacy legislation requires us to obtain your agreement. Please confirm below that you agree to the following statement of the fair processing of your data."
          )}</b
        >
      </p>
      <br />
      <div class="container">
        <div class="scrollable-div">
          {@html $_("privacy_and_consent.privacy_and_consent_text")}
        </div>
      </div>
      <div class="parent-container">
        <div
          style="margin-top:20px; margin-bottom:20px"
          on:click={toggleCheckbox}
          on:keydown={(event) => {
            if (event.key === "Enter" || event.key === "Space") {
              toggleCheckbox();
            }
          }}
        >
          <b style="font-size: 1.5rem; margin-right: 10px; cursor: pointer"
            >{isCheckboxChecked ? "☑  " : "☐  "}
          </b>
          <b style="font-size: 16px ; cursor: pointer">
            {@html $_("privacy_and_consent.I confirm my agreement")}
          </b>
        </div>
      </div>
      <div>
        <button
          class="agree-button-consent"
          disabled={!isCheckboxChecked}
          on:click={agree}>{$_("privacy_and_consent.agree")}</button
        >
        <button class="disagree-button-consent" on:click={disagree}
          >{$_("privacy_and_consent.disagree")}</button
        >
      </div>
    </div>
  </div>
{/if}

<style>
  .modal-consent {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(128, 128, 128, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999;
  }
  .modal-content-consent {
    background-color: #fff;
    padding: 1.7rem;
    border-radius: 4px;
    text-align: center;
    color: #113433;
    animation: fadeIn 0.3s;
    width: 500px;
    display: flex;
    flex-direction: column;
    min-width: 300px;
    overflow-y: hidden;
  }

  @media screen and (max-width: 768px) {
    .modal-content-consent {
      overflow-y: scroll;
    }
  }
  .modal-content-consent h2 {
    margin-top: 0;
  }
  .modal-content-consent p {
    margin-top: 1rem;
    margin-bottom: 1rem;
    text-align: left;
  }
  .agree-button-consent,
  .disagree-button-consent {
    background-color: #1d807c;
    color: #fff;
    border: none;
    padding: 0.75rem 1rem;
    border-radius: 4px;
    cursor: pointer;
    font-size: 1rem;
    transition: background-color 0.3s;
  }
  .agree-button-consent:hover,
  .disagree-button-consent:hover {
    background-color: #146e6a;
  }
  .agree-button-consent:disabled {
    background-color: #ccc;
    color: #888;
    cursor: not-allowed;
  }
  .disagree-button-consent {
    background-color: #eee;
    color: #555;
    margin-left: 0.5rem;
  }
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  .scrollable-div {
    line-height: normal;
    text-overflow: ellipsis;
    text-align: left;
    width: 100%;
    overflow-y: scroll;
    padding: 10px;
    height: 190px;
    border: 1px black solid;
  }

  .consent-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .parent-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }
</style>
